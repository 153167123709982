<template>
  <v-container style="margin-top:0px; padding-top:0px;">

    <v-row align="center" >
      <v-col offset-lg="1" lg="10" offset-xl="2" xl="8"  align="center" >
        <transition name="fade-transition" appear>
          <v-row>
            <v-col align="center" >
            <!-- <h2>Interior. Design. Love</h2> -->

            <v-img
            v-show="$vuetify.breakpoint.mobile"
            src="https://cdn.houstead.com/content/main-image-november-2020.webp"
            aspect-ratio="1"
            srcset="https://cdn.houstead.com/content/main-image-november-2020.webp 480w"
            sizes="(max-width: 600px) 480px, 1200px"
            max-height="825">
            </v-img>

            </v-col>
          </v-row>

        </transition>
        <v-row>
          <v-col sm="6" md="4" xl="4">
            <router-link tag="div" align="center" justify="center" to="/collections/" style="cursor:pointer;">
              <v-img src="https://cdn.houstead.com/content/japanese-art-prints-category.webp" aspect-ratio="1">
              </v-img>
              <h3>Collections</h3>
            </router-link>
          </v-col>
          <v-col sm="6" md="4" xl="4">
            <router-link tag="div" align="center" justify="center" to="/inspiration/" style="cursor:pointer;">
              <v-img src="https://cdn.houstead.com/content/western-art-prints-category.webp" aspect-ratio="1" ref="western-art-prints-category">
              </v-img>
              <h3>Inspiration</h3>
            </router-link>
          </v-col>
          <v-col v-show="!$vuetify.breakpoint.mobile" cols="4" style="cursor:pointer;">
            <router-link tag="div" align="center" justify="center" to="/our-story/">
              <v-img src="https://cdn.houstead.com/content/main-image-november-2020.webp" aspect-ratio="1">
              </v-img>
              <h3>Our Story</h3>
            </router-link>
          </v-col>
        </v-row>


        <v-row>
          <v-col>
            <h2 align="center">{{ $t('views.home.favorites') }}</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>

            <div class="glider" ref="favoritesGlider">
              <div v-for="(product,i) in featuredProducts" :key="i">
                <router-link tag="div" align="center" justify="center" :to="'/product/' + product.url_key" class="product">
                  <img :src="product.base_image.optimized.medium" :alt="product.name + ' poster'" style="width:100%;" :ref="product.url_key" @load="initGlider('favoritesGlider')" @error="imgError(product.url_key)"></img>
                  {{product.name}}
                </router-link>
              </div>
            </div>
          </v-col>
        </v-row>

        <br>
        <v-row>
          <v-col>
            <h2 align="center">{{ $t('views.home.newArrivals') }}</h2>
          </v-col>
        </v-row>


        <div class="glider" ref="newArrivalsGlider">
          <div v-for="(product,i) in newProducts" :key="i">
            <router-link tag="div" align="center" justify="center" :to="'/product/' + product.url_key" class="product">
              <img :src="product.base_image.optimized.medium" :alt="product.name + ' poster'" style="width:100%;" @load="initGlider('newArrivalsGlider')"></img>
              {{product.name}}
            </router-link>
          </div>
        </div>

        <br>
        <v-row>
          <v-col>
            <h2 align="center">Inspiration</h2>
            <br>
          </v-col>
        </v-row>

        <div class="glider" ref="inspirationGlider">
          <div v-for="(inspirationPhoto,i) in inspirationPhotos" :key="i">
            <!-- <router-link tag="div" align="center" justify="center" :to="'/product/' + product.url_key" class="product"> -->
              <img :src="inspirationPhoto.url" style="width:100%; padding:15px; cursor: pointer;" :alt="inspirationPhoto.alt" @load="initGlider('inspirationGlider')"></img>
            <!-- </router-link> -->
          </div>
        </div>

        <br>
        <v-row>
          <v-col>
            <h2 align="center">{{ $t('views.home.modernArt') }}</h2>
          </v-col>
        </v-row>


        <div class="glider" ref="modernArtGlider">
          <div v-for="(product,i) in modernArt" :key="i">
            <router-link tag="div" align="center" justify="center" :to="'/product/' + product.url_key" class="product">
              <img :src="product.base_image.optimized.medium" :alt="product.name + ' poster'" style="width:100%;" @load="initGlider('modernArtGlider')"></img>
              {{product.name}}
            </router-link>
          </div>
        </div>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
// @ is an alias to /src
import ProductService from "../services/ProductService.js"

// Glider.js - https://github.com/NickPiscitelli/Glider.js or https://nickpiscitelli.github.io/Glider.js/
import Glider from 'glider-js';
import 'glider-js/glider.min.css'


export default {
  name: 'home',
  components: {

  },
  data: ()  => ({
    topPageContentLoaded: false,
    items: [],
    inspirationPhotos: [
      {
        alt: 'Scandinavian interior design scene with poster of Senegal parrot on pink wall with mid-century furniture',
        url: 'https://cdn.houstead.com/content/Senegal-parrot-1-500x500.webp?v=45234'
      },
      {
        alt: 'Scandinavian interior design scene with poster of japanese woodblock print on wall with mid-century furniture',
        url: 'https://cdn.houstead.com/content/japanese-art-prints-category.webp'
      },
      {
        alt: 'Scandinavian interior design scene with poster of japanese woodblock print on grey wall with mid-century furniture',
        url: 'https://cdn.houstead.com/content/western-art-prints-category.webp'
      },
      {
        alt: 'Bedroom Scandinavian interior design scene with poster of drawing by Carl Newman print on wall with big bed',
        url: 'https://cdn.houstead.com/content/composition-newman-regular-2.webp?v=45234'
      }
    ],
    modernArt: [],
  }),
  computed: {
    topPageContent: function () {
      if (this.$store.state.app.channel) {
        return this.$store.state.app.channel.home_page_content;
      } else {
        return null;
      }
    },
    products () {
      return this.$store.state.products
    },
    featuredProducts () {
      return this.$store.state.featuredProducts
    },
    newProducts () {
      return this.$store.state.newProducts.reverse()
    },
    locale () {
      if (!this.$store.state.app.locale) {
        // Set default locale
        return {code: process.env.VUE_APP_I18N_LOCALE}
      }
      return this.$store.state.app.locale
    }
  },
  async mounted () {

    this.getProducts();
    this.getNewProducts();
    this.getFeaturedProducts();
    this.getModernArtProducts();

    // this.loadTopPageContent();
    // this.initGlider();

    // console.log(this);
  },
  methods: {
    imgError(ref) {
      // let src = this.$refs[ref].src
      // console.log(this.$refs[ref]);
      // src = src.replace("webp", "jpg");
      // this.$refs[ref].src = src
    },
    async initGlider(ref) {

      new Glider(this.$refs.[ref], {
        slidesToShow: 3.5,
        slidesToScroll: 3,
        draggable: true,
        // dots: '.dots',
        // arrows: {
        //   prev: '.glider-prev',
        //   next: '.glider-next'
        // },
        responsive: [
          {
            // screens greater than >= 300px
            breakpoint: 300,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
              itemWidth: 150,
              duration: 0.5
            }
          },
          {
            // screens greater than >= 775px
            breakpoint: 775,
            settings: {
              // Set to `auto` and provide item width to adjust to viewport
              slidesToShow: 3.5,
              slidesToScroll: 'auto',
              itemWidth: 150,
              duration: 0.25
            }
          },
          {
            // screens greater than >= 1024px
            breakpoint: 1024,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 1,
              itemWidth: 150,
              duration: 0.25
            }
          }
        ]
      })


    },
    async getProducts () {
      ProductService.getProducts()
        .then(products => {

       }).catch(error => {
         console.log(error);
       })
    },
    async getProductsByCategory (category_id) {
      return ProductService.getProductsByCategory(category_id)
        .then(products => {
          return products;
       }).catch(error => {
         console.log(error);
       })
    },
    async getNewProducts () {
      ProductService.getNewProducts()
        .then(products => {
          document.dispatchEvent(new Event("x-app-rendered"));
       }).catch(error => {
         console.log(error);
       })
    },
    async getFeaturedProducts () {
      ProductService.getFeaturedProducts()
        .then(products => {

       }).catch(error => {
         console.log(error);
       })
    },
    async getModernArtProducts() {
      this.modernArt = await this.getProductsByCategory(19);
    },
    async getProductsWebWorker () {

      this.$worker.run(() =>

      ProductService.getProducts()
        .then(products => {

       }).catch(error => {
         console.log(error);
       })
       , [ProductService.getProducts()])
      .then(console.log) // logs 'this.$worker run 1: Function in other thread'
      .catch(
        console.log('worker faild'),

      ) // logs any possible error


    },
    async loadTopPageContent() {
      // this.topPageContent = this.$store.state.app.channel.home_page_content;
      let component = this;
      setTimeout(function () {
        component.topPageContentLoaded = true;
      }, 500);
    }
  }
};
</script>

<style>

h1 {
  font-size: 20pt;
}

#product-carousel .v-image__image--cover {
    background-size: initial;
}

.product {
  cursor: pointer;
}

.glider {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.glider::-webkit-scrollbar {
  display: none;
}

</style>
